import { useEffect } from 'react';

const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      const { target } = entry;
      target.classList.add('is-visible');
      observer.unobserve(target);
    }
  });
};

export default function useIntersectionObserver(selector, config) {
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        // eslint-disable-next-line no-new
        const observer = new IntersectionObserver(handleIntersection, config);
        observer.observe(element);
      });
    }
  }, []);
}
