import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import HubspotForm from 'components/shared/hubspot-form/hubspot-form';

import styles from './form.module.scss';

const cx = classNames.bind(styles);

const Form = ({ title, formId }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pdf-page/hero/illustration.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 265)
        }
      }
    }
  `);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('illustration-wrapper')}>
        <GatsbyImage loading="eager" image={getImage(illustration)} alt="" />
      </div>
      <div className={cx('form-wrapper')}>
        <div className={cx('inner')}>
          <Heading className={cx('title')} size="lg">
            {title}
          </Heading>

          <HubspotForm targetId="hubspotFormWhitePaperPageHero" formId={formId} />
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  title: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

export default Form;
