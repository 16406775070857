import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import shape from './images/shape.svg';
import shapeGray from './images/shape-gray.svg';

import styles from './hero.module.scss';
import Form from './form';

const cx = classNames.bind(styles);

const Hero = (props) => {
  const { title, description, background, formTitle, formId } = props;
  const colorText = background === 'gray' ? 'primary' : 'tertiary';
  return (
    <section className={cx('wrapper', background)}>
      <div className={cx('container', 'inner')}>
        <div className={cx('left')}>
          <Heading className={cx('title')} size="xl" color={colorText}>
            {title}
          </Heading>
          <p className={cx('description', colorText)}>{description}</p>

          <img
            className={cx('shape')}
            src={background === 'gray' ? shapeGray : shape}
            alt=""
            loading="lazy"
            aria-hidden
          />
        </div>
        <Form title={formTitle} formId={formId} />
      </div>
    </section>
  );
};

Hero.defaultProps = {
  formId: null,
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  background: PropTypes.oneOf(['gray', 'blue', 'dark']).isRequired,
  formTitle: PropTypes.string.isRequired,
  formId: PropTypes.string,
};

export default Hero;
