import { useState, useEffect, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import { isSafari } from 'utils/check-browser';

const useVideo = () => {
  const [videoWrapperRef, inView] = useInView({
    threshold: 1,
  });
  const videoRef = useRef();
  const video = videoRef.current;

  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isUnmuteVisible, setUnmuteVisible] = useState(true);
  const [isVideoMuted, setVideoMuted] = useState(true);

  // Play video
  const play = useCallback(() => {
    setIsPlaying(true);
    video.play();
  }, [video]);

  // Pause video
  const pause = useCallback(() => {
    setIsPlaying(false);
    setVideoMuted(true);
    setUnmuteVisible(true);
    video.pause();
  }, [video]);

  // Unmute action executed once Enable audio pressed
  const unmute = useCallback(() => {
    setVideoMuted(false);
    setUnmuteVisible(false);
  }, [video]);

  // Loop a video
  const handleVideoEnd = () => {
    video.play();
  };

  const setVideoReady = () => {
    setIsVideoReady(true);
  };

  // Assign event listeners
  useEffect(() => {
    // Safari does not fire canplay video event
    // https://stackoverflow.com/questions/50051639/javascript-html5-video-event-canplay-not-firing-on-safari

    const canPlayEventName = isSafari ? 'loadedmetadata' : 'canplay';
    const allowedReadyState = isSafari ? 1 : 3;
    if (video) {
      if (video.readyState >= allowedReadyState) {
        setVideoReady();
      }
      if (video.readyState < allowedReadyState) {
        video.addEventListener(canPlayEventName, setVideoReady);
      }
    }
    return () => {
      if (video) {
        video.removeEventListener(canPlayEventName, setVideoReady);
      }
    };
  }, [video]);

  // Play video only when section is in viewport and video loaded
  useEffect(() => {
    // for autoplay, we use the attribute preload="none"
    // so that the video does not load during page load,
    // we start loading it only when inView = true
    if (inView && video?.preload === 'none' && !isVideoReady) {
      video.load();
    }
    if (inView && isVideoReady) {
      play();
    }
    if (!inView && isPlaying) {
      pause();
    }
  }, [inView, play, pause, isPlaying, isVideoReady]);

  return {
    videoWrapperRef,
    inView,
    videoRef,
    isUnmuteVisible,
    isVideoMuted,
    handleVideoEnd,
    unmute,
  };
};

export default useVideo;
