import React from 'react';
import classNames from 'classnames/bind';

import styles from './highlighted-list.module.scss';
import Heading from 'components/shared/heading';

const cx = classNames.bind(styles);

const HighlightedList = ({ title, items }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <div className={cx('inner')}>
        <div className={cx('head')}>
          <Heading tag="h2" size="lg" color="tertiary">
            {title}
          </Heading>
        </div>

        <ul className={cx('list')}>
          {items.map(({ text }, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: text }} />
          ))}
        </ul>
      </div>
    </div>
  </section>
);

export default HighlightedList;
